import React from 'react'
import bgImage from '../Assets/bg-hero.png'
import logo from '../Assets/autovideo-logo.png'
import ReactGA from "react-ga4";
import SignupForm from '../Components/SignupForm';

function FollowupPage() {
    ReactGA.send({ hitType: "pageview", page: "/followup", title: "Followup page" });

    return (
        <div className="relative isolate overflow-hidden bg-cover bg-center bg-no-repeat w-screen h-fit min-h-screen pb-12 flex flex-col justify-center items-center"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img
                                className="h-16 w-auto"
                                src={logo}
                                alt=""
                            />
                        </a>
                        <div className='flex items-center'>
                            <div className="ml-4 text-lg font-semibold leading-6 text-white">Vidius.co</div>
                        </div>
                    </div>
                </nav>

            </header>
            <h1 className='text-white text-4xl md:text-7xl font-bold mt-24'>We are lauching</h1>
            <h1 className='text-white text-4xl md:text-7xl font-bold'>
                <span className="text-4xl md:text-7xl font-bold  inline-block text-transparent bg-clip-text bg-gradient-to-r from-lime-700 via-green-700 to-black px-4">very soon</span>
            </h1>


            <SignupForm />
        </div>
    )
}

export default FollowupPage
