import { Bars3Icon, ChevronRightIcon, PlayIcon, XMarkIcon } from '@heroicons/react/20/solid'
import bgImage from '../Assets/bg-hero.png'
import logo from '../Assets/autovideo-logo.png'
import { Dialog } from '@headlessui/react'
import { useRef, useState } from 'react'

const navigation = [

]

export default function HeroSection() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className="relative isolate overflow-hidden bg-cover bg-center bg-no-repeat w-screen h-fit pb-12"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <img
                                className="h-16 w-auto"
                                src={logo}
                                alt=""
                            />
                        </a>
                        <div className='flex items-center'>
                            <div className="ml-4 text-lg font-semibold leading-6 text-white">Vidius.co</div>
                        </div>
                    </div>

                    <div className="flex lg:flex-1 lg:justify-end">
                        <a href="#pricing" className="text-sm font-semibold leading-6 text-white">
                            Get started <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>

            </header>

            <div className='w-full px-4 md:pt-12'>
                <h1 className='text-white text-4xl md:text-7xl font-bold mt-24'>Never worry about </h1>
                <h1 className='text-white text-4xl md:text-7xl font-bold'>
                    <span className="text-4xl md:text-7xl font-bold inline-block text-transparent bg-clip-text bg-gradient-to-r from-lime-700 via-green-700 to-black px-4 font-bold">content</span>again
                </h1>
            </div>
            <h2 className='text-white text-xl md:text-3xl  mt-4 px-4'>
                Media is a numbers game. We help you win.

            </h2>
            <h2 className='text-white text-base md:text-2xl font-thin mt-1'>
                Done-for-you short clips
            </h2>

            <div className="mt-8">
                <a
                    href='#pricing'
                    type="submit"
                    className=" text-xl flex-none rounded-md px-3.5 py-2.5 font-semibold text-white shadow-lg hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:from-lime-400 hover:via-lime-700 hover:to-lime-700"
                >
                    Get started
                </a>
            </div>


            <div className="lg:mt-0 lg:flex-shrink-0 lg:flex-grow pt-12">
                <div className="mx-auto w-[22.875rem] max-w-full relative">
                    <svg viewBox="0 0 366 729" role="img" className="w-full h-auto">
                        <path
                            fill="#4B5563"
                            d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                        />
                    </svg>
                    <div className="absolute inset-y-[2%] inset-x-[4%] rounded-[2.8rem] overflow-hidden">
                        <div className="w-full h-full flex items-center justify-center overflow-hidden relative">
                            <video
                                ref={videoRef}
                                className="min-w-full min-h-full object-cover cursor-pointer"
                                loop
                                playsInline
                                onClick={togglePlay}
                            >
                                <source src="https://autotube.fra1.digitaloceanspaces.com/Landing/That%20Time%20Einstein%20almost%20became%20a%20President.mp4" type="video/mp4" />
                            </video>
                            {!isPlaying && (
                                <button
                                    onClick={togglePlay}
                                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity hover:bg-opacity-30"
                                >
                                    <PlayIcon className="h-20 w-20 text-white" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
