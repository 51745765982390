import React from 'react'
import bgImage from '../Assets/bg-hero.png'
import logo from '../Assets/autovideo-logo.png'
import ReactGA from "react-ga4";
import SignupForm from '../Components/SignupForm';
import ThankYouCard from '../Components/ThankYouCard';

function ThankYouPage() {
    ReactGA.send({ hitType: "pageview", page: "/thankyou", title: "Thank you page" });

    return (
        <div className="relative isolate overflow-hidden bg-cover bg-center bg-no-repeat w-screen h-screen pb-12 flex justify-center items-center"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img
                                className="h-16 w-auto"
                                src={logo}
                                alt=""
                            />
                        </a>
                        <div className='flex items-center'>
                            <div className="ml-4 text-lg font-semibold leading-6 text-white">Vidius.co</div>
                        </div>
                    </div>


                </nav>
            </header>
            <ThankYouCard />
        </div>
    )
}

export default ThankYouPage
