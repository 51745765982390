import React from 'react'
import HeroSection from '../Components/HeroSection'
import PricingSection from '../Components/PricingSection'
import Gallery from '../Components/Galery'
import SignupForm from '../Components/SignupForm'
import Faqs from '../Components/Faqs'
import Features from '../Components/Features'
import ReactGA from "react-ga4";

function Landing() {

    ReactGA.send({ hitType: "pageview", page: "/landing", title: "Landing page" });

    return (
        <div>
            <HeroSection />
            <Gallery />
            <Features />
            <PricingSection />
            <Faqs />
        </div>
    )
}

export default Landing
