import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/20/solid'
import React from 'react'

const faqs = [

    {
        question: "What exactly does Vidius.co do?",
        answer: "Vidius.co is a service that creates and publishes short-form video content for businesses on their social media platforms. We generate, produce, and post content regularly to help businesses maintain a strong social media presence."
    },
    {
        question: "How often will content be posted for my business?",
        answer: "We offer flexible posting schedules including weekly, daily, or bi-daily options. You can choose the frequency that best suits your social media strategy and budget."
    },
    {
        question: "Do I need to create the content myself?",
        answer: "No, our service is entirely \"done for you.\" We generate and post content based on your business, adapting the style, copy, and images to your specific use case."
    },
    {
        question: "What types of videos do you create?",
        answer: "We specialize in creating short-form video content, often referred to as \"shorts.\" These are brief, engaging videos designed to capture attention quickly on social media platforms."
    },
    {
        question: "How do you ensure the content is relevant to my business?",
        answer: "We adapt our content creation process to your specific business. Our team researches your industry, brand voice, and target audience to create content that aligns with your business goals and image."
    },
    {
        question: "Can I review the content before it's posted?",
        answer: "Yes, we have an approval process in place. All content will be submitted for your review before scheduling and publishing."
    },
    {
        question: "Do the videos include voice-overs?",
        answer: "Yes, we offer dynamic voice-over options. You can choose from our vast library of realistic voice-overs to add a human touch to your videos and enhance viewer engagement."
    },
    {
        question: "Do I need to post the content myself?",
        answer: "No, we handle the entire process, including publishing. Once you've approved the content, we will schedule and publish it for you across your chosen social media platforms."
    },
    {
        question: "How does Vidius.co help my business \"win\" the numbers game in social media?",
        answer: "By consistently publishing high-quality, engaging short-form content, we increase your chances of going viral and reaching a wider audience. More frequent posting typically leads to higher engagement and visibility on social media platforms."
    },
    {
        question: "Can I change my posting frequency or content style over time?",
        answer: "Absolutely! We understand that your needs may change over time. You can adjust your posting frequency or content style as needed to align with your evolving social media strategy."
    },
    {
        question: "What social media platforms do you publish to?",
        answer: "We can publish to all major social media platforms that support short-form video content, including but not limited to TikTok, Instagram Reels, YouTube Shorts, and Facebook Reels. We'll work with you to determine the best platforms for your target audience."
    },
    {
        question: "How do you measure the success of the content?",
        answer: "We track key performance indicators (KPIs) such as views, likes, comments, shares, and overall engagement rates. We provide regular reports so you can see the impact of your content strategy."
    },
    {
        question: "Is there a minimum contract period?",
        answer: "Please contact our sales team for information about contract terms and minimum commitments. We strive to offer flexible options to meet various business needs."
    }
]

function Faqs() {
    return (
        <div className="bg-gradient-to-b from-zinc-950 to-zinc-800">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-white/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-white/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                                                <span className="text-xl font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6 text-lime-300" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6 text-lime-300" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-start text-lg leading-7 text-gray-300">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default Faqs
