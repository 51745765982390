import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
    {
        name: 'Starter',
        id: 'tier-starter',
        href: '#',
        price: { monthly: '$5', annually: 'NA' },
        description: 'Dip your toes in the social media',
        features: ['3 videos per week', 'No topic selection', 'No edits', 'No re-dos'],
        mostPopular: false,
    },
    {
        name: 'Up and running',
        id: 'tier-freelancer',
        href: '#',
        price: { monthly: '$35', annually: '$400' },
        description: 'Establish a presence',
        features: ['1 daily video', 'Topics suggested each month', '5 edits', '5 shorts re-dos'],
        mostPopular: true,
    },
    {
        name: 'Advanced',
        id: 'tier-startup',
        href: '#',
        price: { monthly: '$50', annually: '$550' },
        description: 'Dominate the social media game',
        features: ['2 daily videos', 'Topics suggested each month', '10 edits', '10 shorts re-dos'],
        mostPopular: false,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PricingSection() {
    const [frequency, setFrequency] = useState(frequencies[0])
    const navigate = useNavigate()

    return (
        <div className="bg-gradient-to-b  from-zinc-800 to-zinc-950 py-16 sm:py-24 " id='pricing'>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-lime-400">Pricing</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        Pricing plans for your size
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                    Choose an affordable plan that’s packed with the best features for engaging your audience and growing your socials
                </p>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-lime-400 font-bold">
                    Limited seats. Check with us to see current availability.
                </p>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 mt-12">
                    {tiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular ? 'bg-white/5 ring-2 ring-lime-600' : 'ring-1 ring-white/10',
                                'rounded-3xl p-8 xl:p-10'
                            )}
                        >
                            <div className="flex items-center justify-between gap-x-4">
                                <h3 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                                    {tier.name}
                                </h3>
                                {tier.mostPopular ? (
                                    <p className="rounded-full bg-lime-600 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                                        Most popular
                                    </p>
                                ) : null}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-white">Contact us for pricing</span>
                            </p>
                            <div className='flex justify-center'>
                                <button
                                    href={tier.href}
                                    aria-describedby={tier.id}
                                    className={classNames(
                                        tier.mostPopular
                                            ? 'bg-gradient-to-r from-lime-300 via-lime-600 to-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline-lime-600'
                                            : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                        'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                    )}
                                    type='button'
                                    onClick={() => {
                                        ReactGA.event({
                                            category: "Click",
                                            action: "click",
                                            label: "" + tier.id + "-" + tier.price[frequency.value],
                                        });
                                        navigate("/followup")
                                    }}
                                >
                                    Get in touch
                                </button>
                            </div>
                            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
