import { AdjustmentsVerticalIcon, ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon, MicrophoneIcon, PaperAirplaneIcon, PencilIcon, PhotoIcon, VideoCameraIcon } from '@heroicons/react/20/solid'
import React from 'react'

const features = [
    {
        name: 'Done for you',
        description:
            'We generate and post content based on your business.',
        href: '#',
        icon: VideoCameraIcon,
    },
    {
        name: 'Adaptable',
        description:
            'We adapat style, copy and images to your specific use-case.',
        href: '#',
        icon: AdjustmentsVerticalIcon,
    },
    {
        name: 'Dynamic Voice-Over Options',
        description:
            'Choose from a vast library of realistic voice-overs to bring a human touch to your videos, enhancing viewer engagement.',
        href: '#',
        icon: MicrophoneIcon,
    },
    {
        name: 'Automated Publishing',
        description:
            'We will schedule and publish all approved content for you.',
        href: '#',
        icon: PaperAirplaneIcon,
    },
]



function Features() {
    return (
        <div className="bg-gradient-to-b from-black to-zinc-800 py-24 sm:py-32 -mt-32 md:-mt-64 relative z-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-lime-400">Publish volumes of content</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Establish a strong social image
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                        Social media is a numbers game. The more you post, the most likely it is for your content to go viral. With Vidius.co you will get regular posting of shorts content with easy
                    </p>
                </div>
                <div className="text-start mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                                    <feature.icon className="h-5 w-5 flex-none text-lime-400" aria-hidden="true" />
                                    {feature.name}
                                </dt>
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                    <p className="flex-auto">{feature.description}</p>

                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default Features
